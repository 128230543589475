@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.home-container {
  padding: 20px 0px !important;
  padding-bottom: 100px !important;
}

.m-head {
  
}
.main-head {
  font-weight: bolder;
  padding: 10% 0 3% 0;
  text-align: left;
  align-items:center ;
}
.main-head-sec {
  display: inline-block;
}
.main-head-sec h1 {
  font-weight: 900;
  color: rgba(128, 128, 128, 0.7);
  color: #003366;
  font-size: xxx-large;
  font-family: 'Open Sans', sans-serif;
}
.main-head-sec h3 {
  font-weight: 400 !important;
  color: rgba(128, 128, 128, 0.7);
  font-family: 'Open Sans', sans-serif;
}
.main-head-sec img {
  width: 80%;
  box-shadow: 0px 0px 10px 0px #ffffff7d;
  border-radius: 8px;
}
.main-btns {
  padding: 8% 4%;
}
.main-btn {

}

@media (max-width:600px) {
  .main-btns > div {
    align-items: flex-start !important;
  }
  .main-btn {
    justify-content: flex-start !important;
  }
}

@media (max-width:992px) {
  /* defaul bootstrap row */
  .home-container {
    padding-bottom: 20px !important;
  }
  .row {
    margin-right: 0 !important;
    margin-left: 0 !important;
    margin-top: 5px;
  }
  /* ____ */
  .m-head {
    font-size: 28px !important;
  }
  .main-head {
    padding: 10% 0 2% 0;
    text-align: center;
    flex-direction: column-reverse;
  }
  .main-head-sec {
    display: block;
    flex: none;
    text-align: center;
  }
  .main-head-sec h1 {
    font-weight: 800;
    color: rgba(128, 128, 128, 0.7);
    color: #003366;
    text-shadow: none;
    font-size: 25px !important;
    margin: 20px 0px;
  }
  .main-head-sec h3 {
    font-weight: 600;
    font-size: 20px;
    color: rgba(128, 128, 128, 0.7);
    margin: 20px 0px;
  }
  .main-head-sec img {
    width: 100%;
    margin: 20px 0px;
  }
}

.service-head {
  color: #dcdcdc;
  font-weight: 900;
  font-size: 40px;
}
.service-sub-head {
  color: rgba(128, 128, 128, 0.7);
  font-weight: 200;
  margin: 3%;
  font-size: 30px;
}
.service-sub-btn {
  text-align: center;
  padding: 10px 0px;
}
.services-sub-more-btn {
  text-align: center;
  padding: 2% 0px;
}
.services-sub-less-btn {
  text-align: center;
  padding: 2% 0px;
  display: none;
}
.showLessInstBtn {
  display: none ;
}
.services-sub-more {
  display: none;
  transition: all 0.5s;
}
@media (max-width:992px) {

  .main-head h1 {
    font-size: 40px;
  }
  .main-note h3 {
    font-size: 20px;
  }
  .service-head {
    color: rgba(128, 128, 128, 0.7);
    text-indent: 10px;
    font-size: 25px;
  }
  .service-sub-head {
    font-weight: 200;
    margin: 1%;
    font-size: 23px;
  }

}
/* m paper */
.m-paper {
  margin: 14px 10px;
  cursor: pointer;
  transition:all 0.7s !important;
  padding:25px 10px ;
  text-align:center ;
  position: relative;
  box-shadow: 0px 0px 3px 0px #dcdcdc7d;
  border-radius: 8px;
  display: inline-block;
  border: 1px solid white !important;

}
.m-paper:hover {
  color: #003366;
  border: 1px solid #1e77f486 !important;
  box-shadow: none;
}
.m-paper-avatar-div {
  display: inline-block;
}
.m-paper-avatar {
  transition:all 0.3s !important;
  transform: scale(1);
  background-color: white !important;
  border: 1px solid gainsboro;
  color: rgba(128, 128, 128, 0.7)  !important;
}
.m-paper:hover .m-paper-avatar {
  background-color: #003366 !important;
  border: 1px solid #003366;
  color: rgb(255, 255, 255) !important;
}
.m-paper:hover .m-paper-avatar-txt  {
  color: rgba(128, 128, 128, 0.7) !important;
}
.m-paper:hover .m-paper-avatar-par   {
  color: rgba(128, 128, 128, 0.7) !important;
}
.m-paper-avatar-txt {
  position: relative;
  top: 10px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
  font-size: 15px;
}

.m-paper-avatar-par {
  margin: 0;
  font-size: 14px;
  padding: 5px 10px;
  text-align: left;
  color: rgba(0, 0, 0, 0.7);
}

.m-paper-status-success {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #003366;
  transform:scale(0.7);
}

.m-paper-status-error {
  position: absolute;
  top: 5px;
  right: 5px;
  color: gray;
  transform:scale(0.7);
}

@media (max-width:1200px) {
  .m-paper-text {
    font-size: 13px;
  }  
}
@media (max-width:992px) {
  .m-paper {
    padding: 25px 0;
  }
  .m-paper-text {
    font-size: 15px;
  }  
  .m-paper-avatar-par {
    padding: 5px 15px;
  }
}

/* ____ */

/* model */

.model-prof {
  text-align: center;
  display: inline-block;
  margin: 15px;
  margin-bottom: 20px;
}
.model-avatar {
  width: 100px !important;
  height: 100px !important;
  background-color: #1e77f498 !important;
}
.model-avatar svg{
  transform: scale(2);
}

.model-text {
  display: inline-block;
  padding: 40px 10px;
  text-align: justify;
}
.model-media {
  width: 70%;
  display: inline-block;
  margin: 50px 0px;
}
.model-media-browser {
  box-shadow: 2px 2px 20px #1e77f42d !important;
  border: none !important;
  transform-style: preserve-3d;
  transform: rotate(5deg);
  transition: all 0.5s;
}
.model-media-browser:hover {
  box-shadow: none !important;
  transform-style: preserve-3d;
  transform: rotate(0);
  transform: scale(1.2);
}


@media (max-width:1200px) {

}
@media (max-width:992px) {
  .model-media {
    width: 100%;
    display: inline-block;
    margin: 50px 0px;
  }
  .model-media-browser {
    box-shadow: none !important;
    transform-style: preserve-3d;
    transform: rotate(0);
  }
}

/* ____ */

/* m pricing card */

.m-pricing-card {
  padding: 10px;
  border-radius: 5px;
  margin: 10px 5px;
  background-color: white;
  box-shadow: 0px 0px 10px 0px #dcdcdc7d;
  cursor: pointer;
  border: 1px solid white;
  transition: all 0.5s;
  position: relative;
}
.m-pricing-card:hover {
  border: 1px solid #003366;
}
.m-pricing-card:hover .m-paper-avatar {
  color:#003366 !important;
}
.m-pricing-card-header {
  padding: 5px 0px;
}
.m-pricing-card-head {
  text-align: center;
  color: #adb5bd;
}
.m-pricing-card-body {
  padding: 15px 0px;
  text-align: left;
}
.m-pricing-card-footer {
  text-align: center;
  padding: 10px 0px;
}
/* ___ */


/* faq-cont */
.faq-cont {
  text-align: left;
}
.faq-row {

}
.faq-col {

}
@media (max-width:992px) {
  .faq-row {
    display: inline-block;
  }
  .faq-col {
  
  }
}
/*  */

/* accordian */
.acc-text {
  color: gray;
}
/*  */