@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.cont {
  text-align: center;
  padding: 1rem auto;
  margin: 1rem auto;
  display: flex;
}
.cont-m {
  flex: 4;
}

* {
  font-family: 'Noto Sans', sans-serif;
  text-decoration: none;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: inherit;
}
/*  coloring  */
.prime-color {
  color: #00bf63 !important;
}

.secondary-color {
  color: rgba(128, 128, 128, 0.6) !important;
}
/* m btns */
.prime-btn {
  background-color: #00bf63 !important;
  text-transform: none !important;
}
.prime-success-btn {
  background-color: rgb(237, 247, 237) !important;
  text-transform: none !important;
  box-shadow: none !important;
  color: #4caf50 !important;
}
.secondary-btn {
  text-transform: none !important;
  color: #00bf63 !important;
}
.prime-outlined-btn {
  border: 1px solid #00bf63 !important;
  color: #00bf63 !important;
  text-transform: none !important;
}

/*  ___

/* footer */

.m-footer {
  background-color:#dcdcdc38;
  padding:10px;
  text-align:center;
  position: relative;
}

.m-footer-r {
  margin:5px;
  display:flex;
}

.m-footer-c {
  flex:2;
  margin:5px;
  padding:5px;
  text-align:center;
  position:relative;
  padding-top:18px;
}

.m-footer-logo {
  font-size: 36px;
  color: #00bf63;
  margin: 2px;
  padding: 2px;
  text-decoration: none;
  font-weight: 900;
}
.m-footer-motto {
  margin: 2px;
  padding: 2px;
}

.m-footer-links-head {
  color: gray;
}

.m-footer-links {
  text-align: left;
  /* width: 30%; */
  margin:auto;
}

.m-footer-mlogos {
  display: inline-block;
  font-size: 25px;
  margin: 2px 5px;
}

.m-footer-copyright {
  margin-bottom: 0;
  padding-bottom: 0;
}

/* Mobile  */

@media (max-width:800px) {

}

@media (min-width:1500px) {

}

/* _____ */

/* ____ */

/* card */

.m-card-bor {
  padding: 10px;
}
.m-card-row {
  display: flex;
  padding: 10px;
}
.m-card {
  flex: 1;
  margin: 10px 5px;
  padding: 0px 0px;
  border-radius: 8px;   
  background-color: white;
  position: relative;
}
.m-card-header {
  padding: 20px 20px;
  text-align: left;
}
.m-card-header h1 {
  margin: 0;
  font-size: 20px;
}
.m-card-cont {
  margin: 5px 0px;
  padding: 5px;
  text-align: center;
}
.m-card-footer {
  text-align: right;
  padding: 2px;
  margin: 5px;
}

/* m card graph */

.m-card-graph {
  width: 300px;
  height: auto;
  display: inline-block;
}

@media (max-width:992px) {   
  .m-card-bor {
    padding: 5px;
    text-align: center;
  }
  .m-card {
    width: 98%;
    display: inline-block;
  }
  .m-card-header h1 {
    font-size: 17px;
  }
}

/* card table */

.m-card-tb {
  padding: 5px;
  margin: 5px;
}
.m-card-tb-row {
  border-bottom: 1px solid gainsboro;
  padding: 10px;
  display: flex;
  position: relative;
  text-align: left;
  align-items: center;
}
.m-card-tb-row:hover {
  border-bottom: 1px solid gray;
}
.m-card-tb-col {
  flex: 1;
  padding: 5px;
}
.m-card-tb-hover {
  display: none;
}
.m-card-tb-row:hover .m-card-tb-hover {
  display: block;
  position: absolute;
  top: 5px;
  right: 0px;
}
@media (max-width:992px) {
  .m-card-tb {
    padding: 2px;
    margin: 2px;
  }
  .m-card-tb-col {
    flex: 1;
    padding: 3px;
    font-size: 14px;
  }
}
/* __________ */

/* ___ */

/* prof */

.prof {
  background-repeat: no-repeat;
  border-radius: 4px 4px 0px 0px;
  margin-bottom: 50px;
  background-position: right;
}
.prof-m {
  position: relative;
  top: 0px;
}
.prof-img-bor {
  text-align: center;
}
.prof-img {
  border-radius: 50%;
  display: inline-block;
  overflow: hidden;
  width: 130px;
  height: 130px;
  box-shadow: 0px 0px 10px 0px #dcdcdc7d;
}
.prof-m-img{
  width:100% !important;
  height: 100% !important;
  background-color: rgb(230, 230, 230) !important;
  font-size: 50px !important;
  color: black !important;
  animation-name: profile;
  animation-duration: 2s;
}

.prof-img-ul {
  opacity: 0;
  position: absolute !important;
  bottom: 0 !important;
  top: 0 !important;
  right: 0 !important; 
  left: 0 !important; 
  width: 130px;
  height: 130px;
  background-color:rgba(255, 255, 255, 0.836) !important;
}

.prof-img:hover .prof-img-ul {
  opacity: 1 !important;
}

.prof-m h1 {
  margin: 0;
  padding: 5px;
  font-size: 45px;
  font-weight: 100;
  color: gray;
  text-align: center;
  animation-name: profile;
  animation-duration: 2s;
}

@keyframes profile {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}

@media (max-width:992px) {
  .prof {
    background-position: center;
    margin-bottom: 10px;
  }
  .prof-m {
    border-bottom: 1px solid rgb(228, 228, 228);
    text-align: center;
  }
  .prof-img {
    display: inline-block;
    overflow:hidden;
    width: 100px;
    height: 100px;
    left: 0;
  }
  .prof-img-ul {
    width: 100px;
    height: 100px;
  }
  .prof-m h1{
    font-size: 20px;
    display: block;
    text-align: center;
    left: 0;
    bottom: 6px;
  }
}

/* ____ */

/* m profile medium */

.m-prof-md {
  display: inline-block;
  margin: 8px;
  box-sizing: border-box;
  text-align: center;
  padding: 20px 5px;
  width: 180px;
  border: 1px solid rgb(255, 255, 255);
  box-shadow: 0px 0px 5px 0px #dcdcdc7d;
  position: relative;
  border: none;
}
.m-prof-md:hover {
  cursor: pointer;
}
.m-prof-md-img-bor {
  padding: 5px;
  display: inline-block;
}
.m-prof-md-img {
  width: 120px !important;
  height: 120px !important;
  background-color: gainsboro !important;
}
.m-prof-md p {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width:576px) {
  .m-prof-md-img {
    width: 80px !important;
    height: 80px !important;
    background-color: gainsboro !important;
  }
  .m-prof-md p {
    font-size: 13px;
  }
}
@media (max-width:992px) {
  .m-prof-md-img {
    width: 100px !important;
    height: 100px !important;
    background-color: gainsboro !important;
  }
  .m-prof-md {
    margin: 4px;
    padding: 7px;
    width: 130px;
  }
}
/* ______ */

/* m profile small */

.m-prof-sm {
  display: inline-block;
  margin: 5px;
  box-sizing: border-box;
  text-align: center;
  padding: 10px;
  width: 180px;
}
.m-prof-sm:hover {
  border: 1px solid #00bf63;
  cursor: pointer;
}
.m-prof-sm-img-bor {
  padding: 5px;
  display: inline-block;
}
.m-prof-sm-img {
  width: 120px !important;
  height: 120px !important;
  background-color: gainsboro !important;
}
.m-prof-sm p {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width:576px) {
  .m-prof-sm-img {
    width: 80px !important;
    height: 80px !important;
    background-color: gainsboro !important;
  }
  .m-prof-sm p {
    font-size: 13px;
  }
}
@media (max-width:992px) {
  .m-prof-sm-img {
    width: 100px !important;
    height: 100px !important;
    background-color: gainsboro !important;
  }
  .m-prof-sm {
    margin: 4px;
    padding: 7px;
    width: 130px;
  }
}
/* ______ */


/* m profile small */

.m-prof-xsm {
  display: inline-block;
  margin: 3px;
  box-sizing: border-box;
  text-align: center;
  padding: 5px;
  width: 140px;
}
.m-prof-xsm:hover {
  border: 1px solid #00bf63;
  cursor: pointer;
}
.m-prof-xsm-img-bor {
  padding: 3px;
  display: inline-block;
}
.m-prof-xsm-img {
  width: 100px !important;
  height: 100px !important;
  background-color: gainsboro !important;
}
.m-prof-xsm p {
  font-size: 14px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width:576px) {
  .m-prof-xsm-img {
    width: 60px !important;
    height: 60px !important;
    background-color: gainsboro !important;
  }
  .m-prof-xsm p {
    font-size: 12px;
  }
}
@media (max-width:992px) {
  .m-prof-xsm-img {
    width: 90px !important;
    height: 90px !important;
    background-color: gainsboro !important;
  }
  .m-prof-xsm {
    margin: 4px;
    padding: 5px;
    width: 110px;
  }
}
/* ______ */


/* masonary */ 


.masonry-grid {
  column-gap: 5px;
  width: 100%;
  padding: 5px;
}

.masonry-grid-count-1 {
  column-count: 1;
}

.masonry-grid-count-2 {
  column-count: 2;
}

.masonry-grid-count-3 {
  column-count: 3;
}

.masonry-grid-count-4 {
  column-count: 4;
}

.masonry-grid-count-6 {
  column-count: 6;
}

.masonry-grid-count-8 {
  column-count: 8;
}

.masonry-grid-count-12 {
  column-count: 12;
}

.masonry-grid__item {
  /* Prevent a column from breaking into multiple columns */
  break-inside: avoid;
  margin-bottom: 1rem;
  margin: 0px 5px;
  padding: 5px;
  border-radius: 8px;
  margin-bottom: 15px;
  background-color: white;
  /* box-shadow: 0px 0px 5px 0px #dcdcdc7d; */
  /* border: none; */
  position:relative;
}

@media (max-width:768px) {
  .masonry-grid-count-2 {
    column-count: 1;
  }
  .masonry-grid-count-3 {
    column-count: 1;
  }

  .masonry-grid-count-4 {
    column-count: 1;
  }
  .masonry-grid-count-6 {
    column-count: 1;
  }
  
  .masonry-grid-count-8 {
    column-count: 1;
  }
  .masonry-grid-count-12 {
    column-count: 1;
  }
}

@media (max-width:992px) AND (min-width:768px) {
  .masonry-grid {
    padding: 10px;
  }
  .masonry-grid__item {
    margin: 0px;
    padding: 2px;
    border-radius: 8px;
    margin-bottom: 10px;
  }

  .masonry-grid-count-3 {
    column-count: 2;
  }

  .masonry-grid-count-4 {
    column-count: 2;
  }
  .masonry-grid-count-6 {
    column-count: 2;
  }
  
  .masonry-grid-count-8 {
    column-count: 2;
  }
  .masonry-grid-count-12 {
    column-count: 2;
  }
}

@media (max-width:1200px) AND (min-width:992px) {

  .masonry-grid-count-4 {
    column-count: 3;
  }
  .masonry-grid-count-6 {
    column-count: 3;
  }
  
  .masonry-grid-count-8 {
    column-count: 3;
  }
  .masonry-grid-count-12 {
    column-count: 3;
  }

}

  /* img */

  .masonary {
    position: relative;
  }

  .masonary-img {
    width: 100%;
    border-radius: 5px;
    padding: 5px;
    transition: all 0.1s;
    cursor: pointer;
    border: 1px solid white;
  }
  
  .masonary-img:hover {
    border: 1px solid #00bf63;
  }
  
/* _______ */

/* Gradient paper */

.m-gradient-paper {
  color: rgba(0,7,128,1) !important;
  border: none !important;
  /* background-image: linear-gradient( 109.6deg, rgba(168,212,252,1) 11.2%, rgba(36,146,255,0.81) 91.1% );
  box-shadow: 0px 0px 10px 0px gainsboro; */
  background-image: linear-gradient( 135deg, #ABDCFF 10%, #1976d277 100%);
  transition: all 0.5s;
  padding: 20px 0px;
}

.m-gradient-paper:hover {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.219);
  border: 1px solid #00bf63;
}

/* _________ */

/*  M form */

.m-form {
  padding: 70px;
  position: relative;
}
.m-form-head-bor {
  text-align: center;
  margin: 10px 0;
  margin-bottom: 40px;
}
.m-form-head {
  font-weight: 700;
  font-family: 'Noto Sans', sans-serif;
}
.m-form-in-bor {
  text-align: center;
  margin: 20px 0px;
}

.m-form-in {
  margin:8px 0 !important;
}
@media (max-width:992px) {
  .m-form {
    padding: 30px 10px;
    margin: 50px 0;
    margin-top: 10px;
    box-shadow: none;
    border: none !important;
  }
  .m-form-head {
    font-weight: 600;
    font-family: 'Noto Sans', sans-serif;
  }
  .m-form-in-bor {
    text-align: center;
    margin: 15px 0px;
  }
  .m-form-in {
    margin:4px 0 !important;
  }
}
/* _______ */


/* animation */

.anim-opacity {
  animation-name: opacity;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.anim-fade {
  animation-name: fade;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

@keyframes fade {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}

@keyframes opacity {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}
/* ____ */