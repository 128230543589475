
.cont-add {
  border: 1px solid gainsboro;
  display: inline-block;
  margin: 10px;
  padding: 10px 30px;
  cursor: pointer;
  transition: all 1 ease-in-out;
  border-radius: 5px;
}
.cont-add:hover {
  box-shadow: 0px 0px 3px gainsboro;
}
.cont-sub-add {
  display: flex;
}
.cont-sub-add img {
  flex-grow: 1;
  width: 30px;
  margin-right: 10px;
  filter: invert(48%) sepia(58%) saturate(5461%) hue-rotate(202deg) brightness(94%) contrast(103%);
}
.cont-sub-add h2 {
  flex-grow: 4;
  font-weight: 100;
  color: rgba(128, 128, 128, 0.692);
}